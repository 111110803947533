@import "./constants/_colors.scss";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  background-color: #0d0d0d;
}
html,
body {
  height: 100%;
  // overscroll-behavior: none;
  overflow-x: hidden;
  // overflow-y: hidden;
}
// body {
//   height: auto;
//   overscroll-behavior: none;
//   background-color: #0d0d0d;
//   //overflow-y: hidden;
// }
//mobile screens
@media screen and (max-width: 767px) {
  html {
    overflow-x: hidden;
  }
}
