@import "./_colors.scss";

.buttonShared {
    color: $white;
    border: 0px;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    font-weight: 600;
    transition: background-color 0.2s linear;
    box-shadow: inset 0 0 0 1px rgba(50, 50, 93, 0.1),
        0 2px 5px 0 rgba(50, 50, 93, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.07);
    font-family: "Source Sans Pro", sans-serif;
}

.buttonGhost {
    @extend .buttonShared;

    &:hover {
        background-color: $white;
    }

    &:active {
        background-color: scale-color($white, $lightness: -5%);
    }
}

.buttonRegularOrange {
    @extend .buttonShared;

    background-color: $uiOrange;

    &:hover {
        background-color: scale-color($uiOrange, $lightness: +15%);
    }

    &:focus {
        background-color: scale-color($uiOrange, $lightness: +15%);
    }
}

.buttonGradientOrange {
    @extend .buttonShared;

    background-image: linear-gradient(to right, $uiOrange, $uiYellow);

    &:hover {
        background-image: none;
        background-color: $uiYellow;
    }

    &:focus {
        background-image: none;
        background-color: $uiOrange;
    }
}

.buttonReglarGrey {
    @extend .buttonShared;

    background-color: $mediumGray;

    &:hover {
        background-color: $mediumLightGray;
    }

    &:focus {
        background-color: $darkGray;
    }
}

.buttonReglarDarkGrey {
    @extend .buttonShared;

    background-color: $mediumDarkGray;
    color: #ABABAB;
    font-family: "Roboto";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;

    &:hover {
        background-color: $mediumLightGray;
    }

    &:focus {
        background-color: $darkGray;
    }
}

.buttonGhostGrey {
    @extend .buttonGhost;
    background: $white;
    border-width: 1px;
    border-style: solid;
    box-shadow: none;
    color: #a1a1a1;

    &:hover {
        color: $mediumLightGray;
    }

    &:active {
        color: $mediumLightGray;
    }
}

.buttonGhostBlue {
    @extend .buttonGhost;
    background-color: $uiBlue;

    border-width: 2px;
    border-color: $uiBlue;
    border-style: solid;

    &:hover {
        color: $uiBlue;
    }

    &:active {
        color: $uiBlue;
    }
}

.buttonGhostDarkBlue {
    @extend .buttonGhost;
    background-color: $uiDarkBlue;

    border-width: 2px;
    border-color: $uiDarkBlue;
    border-style: solid;

    &:hover {
        color: $uiBlue;
    }

    &:active {
        color: $uiBlue;
    }
}

.buttonGhostOrange {
    @extend .buttonGhost;
    background-color: $white;
    color: $uiOrange;
    border-width: 2px;
    border-color: $uiOrange;
    border-style: solid;

    &:hover {
        background-color: $uiOrange;
        color: $white;
    }

    &:active {
        background-color: $uiOrange;
        color: $white;
    }
}

.buttonGhostGradientOrange {
    @extend .buttonGhost;
    background-color: $white;
    color: $uiOrange;
    border-width: 2px;
    border-style: solid;
    border-image-source: linear-gradient(to right, $uiOrange, $uiYellow);
    border-image-slice: 1;

    &:hover {
        color: $uiOrange;
    }

    &:active {
        color: $uiOrange;
    }
}

.buttonGhostGreen {
    @extend .buttonGhost;
    background-color: $uiGreen;

    border-width: 2px;
    border-color: $uiGreen;
    border-style: solid;

    &:hover {
        color: $uiGreenAlt;
        border-color: $uiGreenAlt;
    }

    &:active {
        color: $uiGreenAlt;
        border-color: $uiGreenAlt;
    }
}

.buttonGhostDisabled {
    @extend .buttonGhost;
    background-color: #d0dadb;
    color: white;
    cursor: not-allowed;
    border: 1px solid white;
}


.clientLoginText {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
    color: #ABABAB;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
    padding: 25px 0 20px 0;

    &.error {
        color: #CE3530;
    }

    &.link {
        cursor: pointer;
        text-decoration: underline;

        .arrow {
            margin-right: 6px;
        }
    }

}

.returnToSignIn {
    @extend .clientLoginText;
    padding: 23px 0px;
}