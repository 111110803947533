
.mainContainer {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;  
  background-color: #232323;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;  
  gap: 8px;

  .header {
    width: 100%;  
    height: 33%;
    border-bottom: 2px solid #565656;
    display: table;
    vertical-align: middle;

    h1 {
      display: table-cell;
      color: #F26722;
      text-align: center;
      vertical-align: middle;      
    }
  }

  .optionRow {
    height: 33%;
    width: 100%;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 8px;

    .icon {
      width: 20%;
      max-height: 100%;
      padding: 12px;
    }

    p {
      width: 50%;
      height: 100%;
      color: #EFEFEF;
      margin: 0;
      display: flex;
      align-items: center;
      padding: 0;

      @media (min-width: 400px) {
        font-size: 32px;
      }
      @media (min-width: 600px) {
        font-size: 40px;
      }
      @media (min-width: 800px) {
        font-size: 48px;
      }
      @media (min-width: 1000px) {
        font-size: 56px;
      }
    }

    .buttonWrapper {
      width: 30%;
      height: 100%;
      
      a {
        height: 100%;
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;   
      }
      .appstoreBtn {
        //min-width: 80px;
        //width: 100%;
        //max-width: 100%;
        //max-height: 100%;
        margin: 0 auto;
        padding: 8px;

        height: 100%;
        width: 100%;
        object-fit: contain;
      }

    }

  }
}

