@import "./constants/_colors.scss";
@import "./constants/_buttons.scss";

.container {
  height: 100vh;
  max-width: 2500px;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  background-color: $black;

  .headerItemsLoading {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 200px;
      margin: 40px;
    }
  }

  .loadingCircularContainer {
    width: 100%;
    height: 20px;
    margin-top: 13px;
    margin-bottom: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .loadingIcon {
    color: $white;
    height: 20px;
  }
}
