body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #0d0d0d;
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap');

@font-face {
  font-family: 'FavoritStd';
  src: url('./styles/fonts/FavoritStd-Regular.woff') format('woff'), 
  url('./styles/fonts/FavoritStd-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Favorit Bold Extended';
  src: url('./styles/fonts/FavoritStd-BoldExtended.woff') format('woff'), 
  url('./styles/fonts/FavoritStd-BoldExtended.woff2') format('woff2');
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}